import React, { useRef, useState } from 'react';
import * as d3 from "d3";

const AudioRecorder = (props) => {
  const [recordedUrl, setRecordedUrl] = useState('');
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const frequencyArray = useRef(null)
  const drawVisual = useRef(null);
  const isRecordingNow = useRef(false);
  const vizRef = useRef({})
  

  function d3Draw(){
    drawVisual.current = requestAnimationFrame(d3Draw);
    analyser.current.getByteFrequencyData(frequencyArray.current);
    
    const svg = d3.select('#bins')
    const WIDTH = svg.node().getBoundingClientRect().width;
    const HEIGHT = svg.node().getBoundingClientRect().height;
    let rad0 = WIDTH < HEIGHT ? HEIGHT * 0.125 : WIDTH * 0.125;
    let rad1 = WIDTH < HEIGHT ? HEIGHT * 0.35 : WIDTH * 0.35;
    const bufferLength = analyser.current.frequencyBinCount;
    svg.selectAll('line')
      .data(frequencyArray.current)
      .join('line')
        .classed('line',true)
        .attr('x1',(d,i)=>{
          return WIDTH/2+ Math.cos(i/bufferLength*(Math.PI*2) - (Math.PI/2))*rad0;
        })
        .attr('x2',(d,i)=>{
          return WIDTH/2+ Math.cos(i/bufferLength*(Math.PI*2) - (Math.PI/2))*(rad0*1.01 + (rad1 * (d/255)));
        })
        .attr('y1',(d,i)=>{
          return HEIGHT/2 + Math.sin(i/bufferLength*(Math.PI*2) - (Math.PI/2))*rad0;
        })
        .attr('y2',(d,i)=>{
          return HEIGHT/2 + Math.sin(i/bufferLength*(Math.PI*2) - (Math.PI/2))*(rad0*1.01 + (rad1 * (d/255)));
        })
        .attr('stroke',d=>{
          //return `rgb(${d + 50}, ${d + 50}, ${d + 50})`
          return `hsl(${100+(d/200*100)}, ${d+30}%, ${d/255+50}%)`
        });
    if(isRecordingNow.current){
      //console.log('draw circle',vizRef.current);
      const currentTime = vizRef.current.instructionAudio.currentTime-1;
      const trackInfo = vizRef.current.tracks[vizRef.current.currentTrack];
      const trackDuration = trackInfo.end - trackInfo.start;
      const trackCurrent = currentTime - trackInfo.start;
      const flashOpacity = 1.0-Math.sin(currentTime % 1.0);
      
      const fillScale = d3.interpolateLab('rgb(120,0,0)','rgb(0,0,0)');
      const pulseScale = d3.scaleLinear()
        .range([-1,1])
        .domain([0,1]);

      const radianScale = d3.scaleLinear()
        .domain([0,trackDuration])
        .range([0,Math.PI*2])
        .clamp(true);
      const x = Math.cos(radianScale(trackCurrent) - (Math.PI/2)) * rad0;
      const y = Math.sin(radianScale(trackCurrent) - (Math.PI/2)) * rad0;

      svg.selectAll('circle.recordCircle')
        .data([{x,y,fill:fillScale(flashOpacity)}])
        .join('circle')
          .classed('recordCircle',true)
          .classed('visible',true)
          .attr('fill','#c00')
          .attr('cx',d=>WIDTH/2+d.x)
          .attr('cy',d=>HEIGHT/2+d.y)
          .attr('r',rad0*0.05 * Math.abs(Math.sin(pulseScale(flashOpacity))) );


      svg.selectAll("path.arc")
        .data([{degree: radianScale(trackCurrent) - Math.PI/2}])
        .join('path')
        .classed('arc',true)
        .classed('visible',true)
        .attr("transform", `translate(${WIDTH/2},${HEIGHT/2})`)
        .attr("d", d3.arc()({
          innerRadius: rad0-2,
          outerRadius: rad0+2,
          startAngle: 0,
          endAngle: radianScale(trackCurrent)
        }))
        .attr('fill','#c00')

    }
    else{
      svg.selectAll('circle.recordCircle')
        .classed('visible',false);
      svg.selectAll("path.arc")
        .classed('visible',false);
    }

  }
  const startViz = async () => {
    audioContext.current = new AudioContext();
    analyser.current = audioContext.current.createAnalyser();

    const bufferLength = analyser.current.frequencyBinCount;
    
    frequencyArray.current = new Uint8Array(bufferLength);
    
    const audioStream = audioContext.current.createMediaStreamSource( props.audioStream.current );
    audioStream.connect(analyser.current);  
    d3Draw();

  }

  const hideRecorderCircle = async () => {
    console.log('hide recorder circle called');
    d3.select('#bins').select('circle.recordCircle').remove();
    d3.select('#bins').select('path.arc').remove();
  }
  const showRecorderCircle = async () => {
    
  }
  const startRecording = async () => {
    console.log('start the recording');
    try {
      const stream = props.audioStream.current;
      mediaStream.current = stream;
      chunks.current = [];
      if(!mediaRecorder.current){
        mediaRecorder.current = new MediaRecorder(stream);
      }
      showRecorderCircle();
      

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        console.log('onstop called');
        const currentTrack = props.currentTrack;
        console.log('current trackname',currentTrack);
        props.setCurrentTrack(null); //reset onsucess then
        isRecordingNow.current = false;
        const recordedBlob = new Blob(
          chunks.current, { type: 'audio/webm' }
        );
        props.blobs.current[currentTrack] = recordedBlob;
        props.recordingFinished(currentTrack);

        /*const form = new FormData();
        Object.keys(props.blobs.current).map(key=>{
          
          form.append('track',props.blobs.current[key],key+'.webm');
          axios.post('http://54.183.75.232:3000/audio', form);
        })*/
        

        
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        chunks.current = [];
        console.log('set the recorded url',url);
        delete mediaRecorder.current;
      };
      mediaRecorder.current.start();
      
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };
  const stopRecording = () => {
    console.log('stop recording called');
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      hideRecorderCircle();
    }
    /*if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }*/
  };
  if(props.audioStream.current && analyser.current == null){
    console.log('props audiostreqam current',props.audioStream)
    startViz();
  }
  /*isRecording={isRecording} 
  currentTrack={currentTrack} 
  setCurrentTrack={setCurrentTrack}*/ 
  vizRef.current = props;
  //console.log('vizref',vizRef.current);
  if(props.isRecording && !isRecordingNow.current){
    
    isRecordingNow.current = true;
    startRecording();
  }
  if(!props.isRecording && isRecordingNow.current){
    //we just finished a recording
    console.log('trigger stop recording AudioRecorder');
    stopRecording();
  }
  return (
    <div>
    </div>
  );
};
export default AudioRecorder;